.popup {
    font-family: "Montserrat";
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 600px;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    z-index: 10001;
    max-height: 95vh;
    overflow-x: hidden;
    overflow-y: auto;
}

    .popup.noscrollbar::-webkit-scrollbar {
        display: none;
    }

    .popup.noscrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

.tabs .popup {
    margin-top: 40px;
}

.popup .topSection {
    display: flex;
    flex-flow: nowrap;
    align-content: center;
    width: 100%;
    min-height: 50px;
    color: #fff;
    background: #002b44;
    gap: 20px;
}

    .popup .topSection h4 {
        color: #fff;
        text-align: center;
    }

    .popup .topSection .left {
        padding: 20px 0px 0px 20px;
        flex-grow: 1;
    }

    .popup .topSection .center {
        flex-grow: 4;
    }

    .popup .topSection .right {
        padding: 20px 20px 0px 0px;
        flex-grow: 1;
        text-align: right;
    }

.popup .contentSection {
    display: block;
    white-space: normal;
    padding: 40px 10px 40px 10px;
    text-align: center;
}

    .popup .contentSection popup-text {
        text-align: left !important;
    }

    .popup .contentSection .btn {
        margin: 10px;
    }

.popup .contentSectionleft {
    display: block;
    white-space: normal;
    padding: 30px;
    text-align: left;
    margin: 0px 5px 0px 0px;
}

    .popup .contentSectionleft .row {
        padding: 8px;
        font-weight: bolder;
    }

    .popup .contentSectionleft .data {
        font-weight: normal;
        margin: 15px;
    }

    .popup .contentSectionleft .buttons {
        text-align: center;
        padding: 20px 0px 0px 0px;
    }


    .popup .contentSectionleft .btn {
        margin: 10px;
    }

.popup .footerSection {
    display: flex;
}

.popup .btn.btn-modal {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 50%;
}

.popup .btn.btn-modal-full {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 100%;
}


.popup .close-icon {
    /*    color: red;*/
    text-align: right;
    cursor: pointer;
    background: #002b44;
    margin: 0px 0px -2px 0px;
    color: white;
}







/*************************************************************/
/* MEDIA QUIERIES                                            */
/*************************************************************/
@media (max-width: 1024px) {
    .popup {
        width: 95%;
    }
}

@media (max-width: 640px) {
}

@media (max-width: 320px) {
}










.popupLarge.noscrollbar::-webkit-scrollbar {
    display: none;
}

.popupLarge.noscrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tabs .popupLarge {
    margin-top: 40px;
}

.popupLarge .topSection {
    display: flex;
    flex-flow: nowrap;
    align-content: center;
    width: 100%;
    min-height: 50px;
    color: #fff;
    background: #002b44;
    gap: 20px;
}

    .popupLarge .topSection h4 {
        color: #fff;
        text-align: center;
    }

    .popupLarge .topSection .left {
        padding: 20px 0px 0px 20px;
        flex-grow: 1;
    }

    .popupLarge .topSection .center {
        flex-grow: 4;
    }

    .popupLarge .topSection .right {
        padding: 20px 20px 0px 0px;
        flex-grow: 1;
        text-align: right;
    }

.popupLarge .contentSection {
    display: block;
    white-space: normal;
    padding: 40px 10px 40px 10px;
    text-align: center;
}

.popupLarge .contentSectionleft {
    display: block;
    white-space: normal;
    padding: 30px;
    text-align: left;
    margin: 0px 5px 0px 0px;
}

    .popupLarge .contentSectionleft .row {
        padding: 5px;
        font-weight: bolder;
    }

    .popupLarge .contentSectionleft .data {
        font-weight: normal;
    }

    .popupLarge .contentSectionleft .buttons {
        text-align: center;
        padding: 20px 0px 0px 0px;
    }


    .popupLarge .contentSectionleft .btn {
        margin: 10px;
    }

.popupLarge .footerSection {
    display: flex;
}

.popupLarge .btn.btn-modal {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 50%;
}

.popupLarge .btn.btn-modal-full {
    border-radius: unset;
    margin: 0px;
    border: none;
    width: 100%;
}


.popupLarge .close-icon {
    /*    color: red;*/
    text-align: right;
    cursor: pointer;
    background: #002b44;
    margin: 0px 0px -2px 0px;
    color: white;
}



/* Popup BOX LARGER*/

.popupLargeBox {
    font-family: "Montserrat";
    display: inline-block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 1000px;
    background: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
    z-index: 10001;
    max-height: 95vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px;
    font-size: small;
}

    .popupLargeBox .list {
        margin: 2px 4px 0px 0px;
    }

        .popupLargeBox .list .headerrow {
            word-break: break-word;
            border-radius: 2px;
            overflow: overlay;
        }


    .popupLargeBox .contentSection {
        text-align: center;
        margin: 15px;
    }

    .popupLargeBox .close-icon {
        /*    color: red;*/
        text-align: right;
        cursor: pointer;
        background: #002b44;
        margin: 0px 0px -2px 0px;
        color: white;
    }



    .popupLargeBox .topSection {
        display: grid;
        flex-flow: nowrap;
        align-content: center;
        width: 100%;
        min-height: 45px;
        color: #fff;
        background: #002b44;
        gap: 20px;
        border-radius: inherit;
    }

        .popupLargeBox .topSection h4 {
            color: #fff;
            text-align: center;
            padding: 0px 0px 0px 0px;
            margin: 0px;
        }

        .popupLargeBox .topSection .left {
            padding: 20px 0px 0px 20px;
            flex-grow: 1;
        }

        .popupLargeBox .topSection .center {
            flex-grow: 4;
        }

        .popupLargeBox .topSection .right {
            padding: 20px 20px 0px 0px;
            flex-grow: 1;
            text-align: right;
        }
