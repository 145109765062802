/* Toggle Switch */
.toggle-switch {
    display: inline-block;
    width: 100%;
    font-size: 16px;
}

    .toggle-switch .off {
    }

    .toggle-switch .on {
    }

    .toggle-switch .container {
        min-width: unset;
        width: 50px;
        height: 30px;
        float: left;
        padding: 0px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        border: 1px solid #333;
        cursor: pointer;
    }

    .toggle-switch .off .container {
        background: #eee;
    }

    .toggle-switch .on .container {
        background: #7cbd42;
    }


    .toggle-switch .switch {
        width: 20px;
        height: 20px;
        -webkit-border-radius: 13px;
        -moz-border-radius: 13px;
        border-radius: 13px;
        border: 4px solid #333;
        margin: 1px 1px 0px 1px;
    }

    .toggle-switch .off .switch {
        float: left;
        background: #555;
    }

    .toggle-switch .on .switch {
        float: right;
        background: #eee;
    }

    .toggle-switch .turned-on-label {
        height: 30px;
        float: left;
        padding: 2px 5px 0px 5px;
    }


/* List */
.list .headerrow {
    border: 2px solid var(--color-grey);
    display: inline-block;
    width: 100%;
    padding: 5px 0px 5px 0px;
}

.list .datarow {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid var(--color-light-grey);
}

.datarow.selectable:hover {
    background: #eee;
    color: #000;
    cursor: pointer;
}

.listPager {
    background: #eee;
    border: 2px solid var(--color-grey);
    display: inline-block;
    width: 100%;
    padding: 5px 0px 5px 0px;
}


/* Popover */
.popover {
    position: absolute;
    display: block;
    z-index: 20000;
    padding: 0px;
}

    .popover .triangle {
        margin: 0px 0px 0px calc(50% - 10px);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #F47745;
    }

    .popover .popover-content {
        box-shadow: 0px 4px 8px rgb(0 0 0 / 8%);
        border-radius: 6px;
        background: #FFFFFF;
        border: 1.5px solid #F47745;
        padding: 5px;
        color: var(--color-grey);
    }

.delete-trash-icon {
    cursor: pointer;
}

.actions .icon {
    cursor: pointer;
}
