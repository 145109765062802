.payment textarea {
    resize: none;
}

.payment .row .btn {
    margin: 0px;
}

.payment .row .control,
.payment .row .btn {
    margin: 2px 10px 2px 0px;
}

.payment .btn-sm {
    min-width: unset;
    min-height: 20px;
    margin: 0px;
}


.payment .btn.btn-square {
    width: 100px;
    height: 100px;
    padding: 0px;
    min-height: unset;
    min-width: unset;
    margin: 0px 20px 20px 0px;
}


.payment .modal .button {
    background: var(--color-blue);
    color: var(--color-white);
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 20px 0px 20px 0px;
    cursor: pointer;
    float: left;
    border: none;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

    .payment .modal .button.left {
        background: var(--color-orange);
        width: 50%;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: 20px;
    }

    .payment .modal .button.right {
        background: var(--color-green);
        width: 50%;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: 20px;
    }



    .payment .modal .button:disabled {
        background: var(--color-light-grey);
        color: var(--color-grey);
        border: none;
    }




.payment .text-align-left {
    text-align: left;
}

.payment .text-align-right {
    text-align: right;
}

.payment .text-align-center {
    text-align: center;
}

.payment .clickable {
    cursor: pointer
}

.payment .notClickable {
    cursor: not-allowed
}




.payment a {
    cursor: pointer;
}

.payment .codeblock .toolbar {
    width: 100%;
    padding: 5px;
    display: contents;
    cursor: pointer;
}

.payment .codeblock pre {
    font-size: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.payment .blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.payment .loader-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    z-index: 99999999;
}

.payment .backSplash {
    opacity: 0.4;
    cursor: default;
    z-index: 10001;
}


    .payment .backSplash.clear {
        opacity: 0;
    }


.payment .toggleContainer .toggle {
    margin: 0px;
}

.payment .contact-pop-over .pop-over {
    padding: 0px 0px 10px 0px;
    font-size: 16px;
}

.payment .contact-pop-over .menu-item {
    padding: 10px 0px 10px 10px;
    cursor: pointer;
}

    .payment .contact-pop-over .menu-item:hover {
        background: var(--color-dark-blue);
        color: var(--color-white);
    }

.payment .contact-pop-over .menu-separator {
    border: 1px solid var(--color-light-grey);
}


.payment .modal {
    max-width: unset;
    width: 80%;
    overflow: visible;
    padding:10px;
}

.payment .modal .content {
    padding: 10px 10px 0px 10px;
    margin: 0px 0px 10px 0px;
    overflow-y: auto;
    max-height: 75vh
}

.payment .modal .header {
    padding: 0px 10px 0px 10px;
    box-shadow: unset;
    height: unset;
    min-height: 20px;
    display: inline-block;
}

.payment .modal .footer {
    padding: 0px;
}


.payment .modal .content label {
    color: var(--color-orange);
    font-weight: 800;
}

.payment .modal .content .row {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid var(--color-light-grey)
}

.payment .modal .header .btn-close {
    position: absolute;
    right: 30px;
    top: 15px;
    color: var(--color-orange);
    cursor: pointer;
}


.payment .article .article-content .image-wrapper {
    margin: 30px 0px 30px 0px;
}


.payment .article .article-content img {
    width: -webkit-fill-available;
}



.payment .notificationContainer {
    right: 0px;
    top: 110px;
    position: fixed;
    z-index: 10001;
}

    .payment .notificationContainer .notification {
        width: 250px;
        min-height: 50px;
        margin: 0px 10px 10px 0px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        -webkit-box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.5);
        box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.5);
    }

        .payment .notificationContainer .notification .title {
            font-size: 14px;
            font-weight: bolder;
            padding: 5px 0px 5px 5px;
            margin: 0px;
            display: inline-block;
            overflow: hidden;
        }

            .payment .notificationContainer .notification .title .txt {
                text-align: left;
                float: left;
                display: block;
                width: 214px;
            }


            .payment .notificationContainer .notification .title .btn-close {
                text-align: right;
                float: right;
                display: block;
                cursor: pointer;
            }



        .payment .notificationContainer .notification .content {
            font-size: 12px;
            padding: 0px 5px 5px 5px;
            margin: 0px;
        }

        .payment .notificationContainer .notification.info {
            color: #fff;
            background: var(--color-green);
        }

        .payment .notificationContainer .notification.warn {
            color: #fff;
            background: var(--color-orange);
        }

        .payment .notificationContainer .notification.error {
            color: #fff;
            background: var(--color-error);
        }






.payment .list.provider .datarow {
    border-bottom: none;
}

.payment .list.method .datarow {
    border-bottom: 1px solid var(--color-light-grey);
}



.payment .provideredit .row {
    padding: 0px 0px 20px 0px
}

.payment .provideredit .metadata .row {
}

.payment .provideredit .metadata .list .row {
    border-top: 1px solid var(--color-light-grey);
}

    .payment .provideredit .metadata .list .row .btn {
        margin: 10px 10px 10px 0px;
        padding: 0px;
        color: red;
        min-width: 6em;
    }


.payment .popover .journal {
    width: 150px;
}

    .payment .popover .journal .row {
        padding: unset;
        border: unset;
    }



    .payment .transactionView .row {
        padding: 8px;
        border-bottom: 1px solid var(--color-light-grey);
    }

.payment .transactionView .content {
    color: var(--color-orange);
}

.payment color-error {

    color: var(--color-error);
}

#payment-container {
    height: 520px;
}